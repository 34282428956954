<template>
    <div class="signal-card rounded-md text-left p-5 mb-5 border-2">
        <div class="flex justify-between items-center">
            <span style="font-weight: 800; font-size: 1.01rem;">Signals determined from latest news - Daily</span>
            <button class="k-bg-pink text-white px-4 py-1 rounded-md" @click="navigateToStrategy">View Detailed
                Strategy</button>
        </div>
        <div v-for="news in selectednewsSignalList">
            <NewsArticle :data="news" />
        </div>
    </div>
</template>

<script>
import NewsArticle from './NewsArticle.vue';
import { newsSignals } from "../utils/constants.js";
export default {
    data() {
        return {
            newsSignals: newsSignals,
            laneId: 1,
            selectednewsSignal: []
        }
    },
    props: {
        selectedLaneID: Number,
        newsData: Object
    },
    watch: {
        selectedLaneID(newVal) {
            this.laneId = newVal
        },
        newsData(newVal) {
            this.selectednewsSignal = JSON.parse(newVal)
            this.selectednewsSignal.forEach((val) => {
                const parsedStrategyDetails = JSON.parse(val["Strategy Details"])

                val.urls = []
                const regex = /\[([^\]]+)]/;
                const matches = parsedStrategyDetails["Data Fetched from"].match(regex);
                if (matches) {
                    const arrayString = matches[1]; // matches[0] contains the full match, matches[1] contains the captured group
                    val.urls = arrayString.split(',').map(item => item.trim().replaceAll("'", ''));
                }

                val.Strategy = this.formatNewsStrategy(parsedStrategyDetails["As per Latest News"])
            })
        }
    },
    components: {
        NewsArticle
    },
    computed: {
        selectednewsSignalList() {
            return [this.selectednewsSignal[this.laneId - 1]]
        }
    },
    methods: {
        navigateToStrategy() {
            const data = {
                laneId: this.laneId,
                source: 'news'
            }
            this.$store.dispatch('setLane', data);
            if(this.$route.name == "signals"){
                this.$router.push({ name: 'strategy' })
            } else {
                this.$router.push({ name: 'strategyaluminum' })
            }
            
        },
        formatNewsStrategy(text) {
            text = text.replaceAll("'", '"')
            if (this.isJSON(text)) {
                const news = this.extractNestedValues(JSON.parse(text))
                let newsString = ""

                Object.entries(news).forEach(([key, value]) => {
                    newsString += `<strong>${key}</strong><br>
                    ${this.formatArray(value)}<br>`
                });
                return newsString;
            } else {
                return text
            }
        },
        formatArray(value) {
            let str = ""
            for (const val of value) {
                str += `<li>${val}</li>`
            }
            return str
        },

        isJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },
        extractNestedValues(obj) {
            // Check if the input object is already in the desired format
            if (Object.values(obj).every(Array.isArray)) {
                return obj;
            }
            const result = {};

            // Recursive function to traverse the nested object
            function traverse(obj, parentKeys) {
                for (const key in obj) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        traverse(obj[key], [...parentKeys, key]); // Recursively traverse nested objects
                    } else {
                        const mainKeys = parentKeys.join(' > '); // Combine main keys into a single string
                        if (!result[mainKeys]) {
                            result[mainKeys] = [];
                        }
                        result[mainKeys].push(obj[key]); // Add value to result array
                    }
                }
            }

            traverse(obj, []);
            return result;
        }



    }
}
</script>

<style scoped></style>