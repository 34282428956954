import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/tailwind.css";
import router from "./router";
import store from './store/store';
import "./assets/css/global.css";
import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// Retrieve user data from localStorage on page load
const savedUser = localStorage.getItem('user');
if (savedUser) {
  store.commit('setUser', JSON.parse(savedUser));
}

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App).use(vuetify).use(store).use(router).mount("#app");
