<template>
    <div class="signal-card rounded-md text-left p-5 my-5 border-2">
        <div class="flex justify-between items-center">
            <span style="font-weight: 800; font-size: 1.01rem;">Signals determined from analyst reports commentary - Quarterly</span>
            <button class="k-bg-pink text-white px-4 py-1 rounded-md" @click="navigateToStrategy">View Detailed Strategy</button>
        </div>
        <div v-for="signal in selectedMarketSignalList">
            <ReportArticle :data="signal"/>
        </div>
    </div>
</template>

<script>
import ReportArticle from './ReportArticle.vue';
import { marketSignals } from "../utils/constants.js";
export default {
    data(){
        return {
            marketSignals:marketSignals,
            laneId: 1,
            selectedMarketSignal: []
        }
    },
    components: {
        ReportArticle
    },
    props: {
        selectedLaneID: Number,
        marketData: Object
    },
    watch: {
        selectedLaneID(newVal) {
            this.laneId = newVal
        },
        marketData(newVal) {
            this.selectedMarketSignal = JSON.parse(newVal)
            this.selectedMarketSignal.forEach((val) => {
                const parsedStrategyDetails = JSON.parse(val["Strategy Details"])
                val.Strategy = parsedStrategyDetails["As per Market Reports"]
            })
        }
    },
    computed: {
        selectedMarketSignalList(){
            return [this.selectedMarketSignal[this.laneId-1]]
        }
    },
    methods: {
        navigateToStrategy(){
            const data = {
                laneId: this.laneId,
                source: 'market'
            }
            this.$store.dispatch('setLane', data);
            this.$store.dispatch('setLane', data);
            if(this.$route.name == "signals"){
                this.$router.push({ name: 'strategy' })
            } else {
                this.$router.push({ name: 'strategyaluminum' })
            }
        }
    }
}
</script>

<style scoped>
</style>