<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">
    <Navbar :otherInfoVisible="false" />
    <Loader :loading="loading" />
    <ConfigurePanel :isOpen="showConfigurePanel" @close-panel="closeConfigurePanel" />
    <DraftsPanel :isOpen="showDraftsPanel" @close-panel="closeDraftsPanel" />
    <SaveDraftPopup :isVisible="showPopup" @accept="handleAccept" @close="handleClose" />
    <div class="chatbot-container bg-white">
      <div class="flex w-full px-4 pt-5 rounded-md items-center text-left h-1/5" style="background-color: #F4F4F4;">
        <div class="w-1/6 mr-10">
          <label class="font-bold flex items-center">
            <img :src="require('@/assets/img/caret-left-black.svg')" alt="" class="mr-2 cursor-pointer"
              @click="$router.push({ name: 'signalsaluminum' });">
            <span>Select Region</span>
          </label>
          <div class="mb-5 mt-2 k-br-cyan rounded-lg relative">
            <select @change="strategy = ''" v-model="activeLane"
              class="form-select w-full cursor-pointer px-3 pr-10 py-2 border font-semibold rounded-md bg-white">
              <option v-for="lane in aluminumLaneNames" :key="lane.id" :value="lane.id">{{ lane.name }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <!-- Dropdown Icon (Downward-pointing Triangle) -->
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path fill="#55A8B5" d="M1 6h18L10 18 1 6z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="w-5/6 text-sm text-left">
          <div class="flex items-center space-x-4 text-xs">
            <div>
              <label for="marketPriceTrend" class="block mb-2 text-xs">Market Price Trend</label>
              <div class="mb-5 rounded-lg relative" style="width: 150px;">
                <select v-model="marketPriceTrend"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer bg-white">
                  <option value="Rising">Rising</option>
                  <option value="Stable">Stable</option>
                  <option value="Falling">Falling</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="marketCapacityTrend" class="block mb-2 text-xs">Market Capacity Trend</label>
              <div class="mb-5 rounded-lg relative" style="width: 150px;">
                <select v-model="marketCapacityTrend"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer bg-white">
                  <option value="Rising">Rising</option>
                  <option value="Stable">Stable</option>
                  <option value="Falling">Falling</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="priceBenchmarking" class="block mb-2 text-xs">Price Benchmarking</label>
              <div class="mb-5 rounded-lg relative" style="width: 150px;">
                <select v-model="priceBenchmarking"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer bg-white">
                  <option value="Higher than market">Higher than market</option>
                  <option value="Lower than market">Lower than market</option>
                  <option value="As per market">As per market</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="lowCostSourcing" class="block mb-2 text-xs">Low Cost Sourcing</label>
              <div class="mb-5 rounded-lg relative" style="width: 150px;">
                <select v-model="lowCostSourcing"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer bg-white">
                  <option value="Yes">Yes</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <button v-if="scenarioAnalysisEnabled" v-ripple @click="refresh"
              class="border k-pink-button rounded-md">Refresh
              Strategy</button>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="flex text-left">
          <div class=" w-full"> <!--  mr-5 -->
            <div class="flex w-full justify-between space-x-8 mb-3"> <!--  w-4/5 -->
              <button v-ripple class="analysis-button w-1/4 py-2 ml-0" @click="openConfigurePanel">Configure</button>
              <button v-ripple class="analysis-button w-1/4 py-2" @click="scenarioAnalysisEnabled = true">Do Scenario
                Analysis</button>
              <button v-ripple class="analysis-button w-1/4 py-2" @click="openEditStrategy">Edit Strategy</button>
              <button v-ripple class="analysis-button w-1/4 py-2" @click="openDraftsPanel">View Your Drafts</button>
            </div>
            <div class="flex">

              <Timeline class="w-1/5" style="min-height: 55vh;" @date-selected="getSignals" />

              <div class="p-3 w-4/5 k-br-cyan rounded-md ml-5 overflow-y-auto strategy-area" style="max-height: 55vh;">
                <p class="font-semibold mb-2">AI Recommended Strategy:</p>
                <img v-if="!showStrategyOutput" src="@/assets/gif/loading-gif.gif" alt="Loading..."
                  class="loading-gif h-20" />
                <p v-if="showStrategyOutput" v-html="getStrategy" :contenteditable="editStrategy" ref="content">
                </p>
                <div v-if="editStrategy" class="flex justify-end space-x-3 mt-5">
                  <button v-ripple class="k-white-button" @click="closeEditStrategy">Cancel</button>
                  <button v-ripple class="k-white-button" @click="showPopup = true">Draft</button>
                  <button v-ripple class="k-pink-button" @click="closeEditStrategy">Publish Draft</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from '@/components/Loader.vue'
import ChatbotOptionsList from "@/components/ChatbotOptionsList.vue";
import ChatbotFilters from "@/components/ChatbotFilters.vue";
import ChatbotTables from "@/components/ChatbotTables.vue";
import Navbar from "@/components/NavbarComponent.vue";
import Popup from "@/components/Popup.vue";
import NewsSignals from '@/components/NewsSignals.vue';
import ReportSignals from '@/components/ReportSignals.vue';
import Timeline from '@/components/Timeline.vue';
import ConfigurePanel from '@/components/ConfigurePanel.vue';
import DraftsPanel from '@/components/DraftsPanel.vue';
import SaveDraftPopup from '@/components/SaveDraftPopup.vue';
import { lanes, aluminumRefreshStrategy, newsStrategy, aluminumLaneNames, marketStrategy, mainStrategy } from "../utils/constants.js";

export default {
  name: "StrategyView",
  components: {
    ChatbotOptionsList,
    ChatbotFilters,
    ChatbotTables,
    NewsSignals,
    ReportSignals,
    Navbar,
    Popup,
    Loader,
    Timeline,
    DraftsPanel,
    ConfigurePanel,
    SaveDraftPopup
  },
  data() {
    return {
      marketPriceTrend: '',
      marketCapacityTrend: '',
      priceBenchmarking: '',
      lowCostSourcing: '',
      lanes: lanes,
      aluminumLaneNames: aluminumLaneNames,
      newsStrategy: newsStrategy,
      marketStrategy: marketStrategy,
      mainStrategy: mainStrategy,
      activeStrategySet: mainStrategy,
      strategy: "",
      activeLane: 1,
      showStrategyOutput: true,
      aluminumRefreshStrategy: aluminumRefreshStrategy,
      userMessage: "",
      showConfigurePanel: false,
      showDraftsPanel: false,
      editStrategy: false,
      scenarioAnalysisEnabled: false,
      messages: [],
      loading: false,
      isLoading: false,
      initialComponent: null,
      showPopup: false,
      popupQuestion: "Are you sure you want to start a new chat?"
    };
  },
  computed: {
    getStrategy() {
      if (this.strategy != "") {
        return this.strategy
      }
      const x = this.activeStrategySet[this.activeLane - 1];
      // const x = this.activeStrategySet.find((lane) => lane.id === this.activeLane);
      this.marketPriceTrend = x["Markets Price Trend (Quarterly)"]
      this.marketCapacityTrend = x["Markets Capacity Trend(Quarterly)"] || x["Markets Capacity Trend"]
      this.priceBenchmarking = x["Price Benchmark Insights"]
      this.lowCostSourcing = "Yes"
      return x ? this.formatStrategy(x) : '';
    }
  },
  mounted() {
    this.setup()
  },
  watch: {
    getStrategy() {
      this.showStrategyOutput = false;
      setTimeout(() => {
        this.showStrategyOutput = true;
      }, 3000);
    },
  },
  methods: {
    formatStrategy(strategy){
      if (this.$store.getters.getLane.source == 'news') {
        return this.formatNewsStrategy(strategy)
      } else {
        return this.formatMarketStrategy(strategy)
      }
    },
    formatNewsStrategy(strategy){
      let strategyDetails = strategy["Strategy Details"]
      if (strategyDetails){
        strategyDetails = JSON.parse(strategyDetails)
        return `<strong>Strategy:</strong><br>${strategyDetails["Strategy"]}<br><br>
        <strong>Supporting Rationale:</strong><br>${strategyDetails["Supporting Rationale"]}<br><br>
        <strong>Data Fetched From:</strong><br>${strategyDetails["Data Fetched from"]}<br><br>
        <strong>As Per Latest News:</strong><br>${this.formatNewsStrategyy(strategyDetails["As per Latest News"])}<br><br>`.replaceAll('\n', '<br>')
      }
      return ``
    },
    formatMarketStrategy(strategy){
      let strategyDetails = strategy["Strategy Details"]
      if (strategyDetails){
        strategyDetails = JSON.parse(strategyDetails)
        return `<strong>Strategy:</strong><br>${strategyDetails["Strategy"]}<br><br>
        <strong>Supporting Rationale:</strong><br>${strategyDetails["Supporting Rationale"]}<br><br>
        <strong>Data Fetched From:</strong><br>${strategyDetails["Data Fetched from"]}<br><br>
        <strong>As Per Market Reports:</strong><br>${strategyDetails["As per Market Reports"]}<br><br>`.replaceAll('\n', '<br>')
      }
      return ``
    },
    formatNewsStrategyy(text) {
            text = text.replaceAll("'", '"')
            if (this.isJSON(text)) {
              const news = this.extractNestedValues(JSON.parse(text))
                let newsString = ""

                Object.entries(news).forEach(([key, value]) => {
                    newsString += `<strong>${key}</strong><br>
                    ${this.formatArray(value)}<br>`
                });
                return newsString;
            } else {
                return text
            }
        },
        formatArray(value){
            let str = ""
            for(const val of value){
                str+= `<li>${val}</li>`
            }
            return str
        },

        isJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },
        extractNestedValues(obj) {
            // Check if the input object is already in the desired format
            if (Object.values(obj).every(Array.isArray)) {
                return obj;
            }
            const result = {};

            // Recursive function to traverse the nested object
            function traverse(obj, parentKeys) {
                for (const key in obj) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        traverse(obj[key], [...parentKeys, key]); // Recursively traverse nested objects
                    } else {
                        const mainKeys = parentKeys.join(' > '); // Combine main keys into a single string
                        if (!result[mainKeys]) {
                            result[mainKeys] = [];
                        }
                        result[mainKeys].push(obj[key]); // Add value to result array
                    }
                }
            }

            traverse(obj, []);
            return result;
        },
    openConfigurePanel() {
      this.showConfigurePanel = true;
    },
    closeConfigurePanel() {
      this.showConfigurePanel = false;
    },
    openDraftsPanel() {
      this.showDraftsPanel = true;
    },
    closeDraftsPanel() {
      this.showDraftsPanel = false;
    },
    openEditStrategy() {
      this.editStrategy = true;
      this.$refs.content.focus();
    },
    closeEditStrategy() {
      this.editStrategy = false;
    },

    handleAccept() {
      this.showPopup = false;
    },
    handleClose() {
      this.showPopup = false;
    },
    refresh() {
      this.strategy = this.aluminumRefreshStrategy.find((x) => this.marketPriceTrend == x.marketPriceTrend &&
        this.marketCapacityTrend == x.marketCapacityTrend &&
        this.priceBenchmarking == x.priceBenchmarking &&
        this.lowCostSourcing == x.lowCostSourcing).strategy || ""
    },
    setup() {
      this.activeLane = this.$store.getters.getLane.laneId;
      const resp = this.$store.getters.getStrategy
      if (this.$store.getters.getLane.source == 'news') {
        this.activeStrategySet = JSON.parse(resp.Result.news)
      } else {
        this.activeStrategySet = JSON.parse(resp.Result.market)
      } 
    },
    getSignals() {
      this.loading = true;
      const apiUrl = `${process.env.VUE_APP_ALUMINUM_API_BASE_URL}/api-aluminum/signals`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.$store.dispatch('setStrategy', response.data);
          console.log(this.$store.getters.getAllDates)
          this.setup()
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.chatbot-container {
  height: 85vh;
  width: 90vw;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.analysis-button {
  background-color: #55A8B5;
}

.k-pink-button {
  color: white;
  background-color: #A0186A;
}

.k-white-button {
  background-color: white;
  border: 1px solid #A0186A;
  color: #A0186A;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #a0186a;
  color: #fff;
}

.chatbot {
  background-size: cover;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.strategy-area::-webkit-scrollbar {
  width: 4px;
}

.strategy-area::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>