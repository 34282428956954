<template>
  <div class="container mx-auto mt-5 text-xs border-2 rounded-xl shadow-xl">
    <p class="py-2 px-4 font-semibold">Top lanes</p>
    <table class="min-w-full">
      <thead>
        <tr>
          <th class="py-1 px-4 border-b">Lane</th>
          <th class="py-1 px-4 border-b">Spend</th>
          <th class="py-1 px-4 border-b">volume</th>
          <th class="py-1 px-4 border-b">Primary Carrier</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.lane">
          <td class="py-1 px-4 border-b">{{ item.lane }}</td>
          <td class="py-1 px-4 border-b">{{ item.spend }}</td>
          <td class="py-1 px-4 border-b">{{ item.volume }}</td>
          <td class="py-1 px-4 border-b">{{ item.carrier }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
/* Add your other styles here */
</style>

<script>
export default {
  data() {
    return {
      items: [
        {
          lane: " Yantian WC",
          carrier: "CMA",
          volume: "14062.16",
          spend: " 8454",
        },
        {
          lane: "Ningbo WC",
          carrier: "CMA",
          volume: "12420.75",
          spend: "8154",
        },
        {
          lane: " Shanghai WC",
          carrier: "CMA",
          volume: "10230.21",
          spend: "8154",
        },
      ],
    };
  },
};
</script>
