<template>
  <div class="actions text-white h-screen flex flex-col justify-between items-center">
    <Navbar :labelVisible="false" />
    <div>
      <div class="text-3xl mb-5">
        <p>
          Hello <span class="font-bold">{{ userFromStore }}</span>! I can see data uploaded,
        </p>
        <p>for the following categories, which</p>
        <p>one should I help you with?</p>
      </div>
      <div class="grid grid-cols-2 gap-4 mb-15">
        <button v-ripple @click="navigate('ocean')" class="action-button font-semibold rounded-lg py-2">
          Ocean Freight
        </button>
        <button v-ripple @click="navigate('aluminum')" class="action-button font-semibold rounded-lg py-2">
          Aluminum
        </button>
        <button v-ripple class="bg-gray-500 rounded-lg py-2" disabled>
          Paper Packaging
        </button>
        <button v-ripple class="bg-gray-500 rounded-lg py-2" disabled>
          Steel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/NavbarComponent.vue";
export default {
  name: "ActionsView",
  components: {
    Navbar,
  },
  computed: {
    userFromStore() {
      const firstName = this.$store.getters.getUser.firstName;
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    },
    enableAluminum(){
        if(this.$route.params.mode == 'spend-analysis' || this.$route.params.mode == 'strategy-recommendation' || this.$route.params.mode == 'price-benchmarking'){
          return true
        }
      return false
    }
  },
  methods: {
    navigate(category) {
      if (this.$route.params.mode == "strategy-recommendation") {
        if(category == "aluminum"){
          this.$router.push({ name: 'signalsaluminum'})
        } else {
          this.$router.push({ name: 'signals'})
        }

      } else {
        this.$router.push({ name: 'chatbot', params: { mode: this.$route.params.mode, category: category }})
      }
    }
  }
};
</script>

<style scoped>
.actions {
  background-image: url("../assets/img/bot-bg.png");
  background-size: cover;
}

.action-button {
  background-color: white;
  color: #a0186a;
}

.action-button:active {
  color: white;
  background-color: #a0186a;
}
</style>
