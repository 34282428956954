<template>
  <div>
    <div v-if="isOpen" class="flex flex-col justify-between panel text-left rounded-r-lg">
      <div>
        <div class="flex justify-between items-center mb-5">
          <span class="font-bold">Configure</span>
          <button @click="$emit('close-panel')" class="close-button">×</button>
        </div>

        <div>
          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Client's Objective</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
                <option value="Cost Reduction">Cost Reduction</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Client's Capacity/ Volume Forecast</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
                <option value="Rising">Rising</option>
                <option value="Stable">Stable</option>
                <option value="Falling">Falling</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Contract Expiry Horizon</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Volume Commitment with Incumbent</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Spot vs Contract Split</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label for="marketCapacityTrend" class="block mb-1 text-xs">Contract Penalty</label>
            <div class="mb-5 rounded-lg relative">
              <select v-model="marketCapacityTrend"
                class="form-select w-full px-3 pr-10 py-1 border rounded-md cursor-pointer bg-white">
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <!-- Dropdown Icon (Downward-pointing Triangle) -->
                <svg class="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M1 6h18L10 18 1 6z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button v-ripple @click="refresh" class="border k-pink-button rounded-md py-2">Refresh Strategy</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen:Boolean
  }
};
</script>

<style scoped>
.panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  /* Adjust the width as needed */
  height: 100%;
  background-color: #fff;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: transform 0.3s ease;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.k-pink-button {
  color: white;
  background-color: #A0186A;
}
</style>