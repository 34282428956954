<template>
    <div class="signal-card rounded-md text-left p-5 my-5 border-2">
        <div class="flex justify-between items-center">
            <span style="font-weight: 800; font-size: 1.01rem;">Signals determined from analyst reports tabular data - Quarterly</span>
            <button class="k-bg-pink text-white px-4 py-1 rounded-md" @click="navigateToStrategy">View Detailed Strategy</button>
        </div>
        <div v-for="signal in selectedMainSignalList">
            <ReportArticle :data="signal"/>
        </div>
    </div>
</template>

<script>
import ReportArticle from './ReportArticle.vue';
import { mainSignals } from "../utils/constants.js";
export default {
    data(){
        return {
            mainSignals:mainSignals,
            laneId: 1,
            selectedMainSignal: []
        }
    },
    components: {
        ReportArticle
    },
    props: {
        selectedLaneID: Number,
        mainData: Object
    },
    watch: {
        selectedLaneID(newVal) {
            this.laneId = newVal
        },
        mainData(newVal) {
            this.selectedMainSignal = JSON.parse(newVal)
            this.selectedMainSignal.forEach((val) => {
                const parsedStrategyDetails = JSON.parse(val["Strategy Details"])
                val.Strategy = parsedStrategyDetails["As per latest data"]
            })
        }
    },
    computed: {
        selectedMainSignalList(){
            return [this.selectedMainSignal[this.laneId-1]]
        }
    },
    methods: {
        navigateToStrategy(){
            const data = {
                laneId: this.laneId,
                source: 'main'
            }
            this.$store.dispatch('setLane', data);
            this.$router.push({ name: 'strategy'})
        }
    }
}
</script>

<style scoped>
</style>