<template>
    <div>
        <div class="px-15 py-8 text-left" v-if="responseData">
            <div class="">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th v-for="(column, index) in outputTable.columns" :key="index"
                                class="px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                {{ column }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, rowIndex) in outputTable.rows" :key="rowIndex" class="border border-gray-200">
                            <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="px-6 py-4 whitespace-no-wrap">
                                {{ cell }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-5" v-html="getInsights">
            </div>

        </div>
        <div class="text-left px-15">
            <span class="px-5 py-2 k-bg-pink text-white mt-5 rounded-lg ">{{ source }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            outputTable: {
                columns: [],
                rows: []
            },
            insights: [],
            source: ""
        };
    },
    props: {
        responseData: {
            type: Object,
            default: null
        }
    },
    computed: {
        getInsights() {
            return this.insights[1].replaceAll('\n', '<br>')
        }
    },
    watch: {
        responseData: {
            handler(newValue, oldValue) {
                if (newValue && newValue.output_table && newValue.output_table.length > 0) {
                    this.outputTable.columns = [...newValue.output_table[0].columns]; // Ensure reactivity by creating a new array
                    this.outputTable.rows = [...newValue.output_table[0].rows]; // Ensure reactivity by creating a new array
                    this.insights = [...newValue.answer_docs[0].bp];
                    this.source = newValue.source
                }
            },
            immediate: true,
            deep: true // Trigger the watch immediately when the component is mounted
        }
    }
};
</script>