<!-- Popup.vue -->

<template>
    <div v-if="isVisible" class="popup-overlay">
      <div class="popup-dialog">
        <p><strong>{{ question }}</strong></p>
        <div class="popup-buttons mt-5">
          <button v-ripple @click="handleClose" class="px-10 py-2 mr-5 rounded-md close">No</button>
          <button v-ripple @click="handleAccept" class="px-10 py-2 border rounded-md k-bg-pink text-white">Yes</button>       
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      question: {
        type: String,
        default: "Do you want to proceed?"
      }
    },
    methods: {
      handleAccept() {
        this.$emit("accept");
      },
      handleClose() {
        this.$emit("close");
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add some styling for the overlay and dialog */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-dialog {
    background: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .close {
  border: 1px solid #A0186A;
  color: #A0186A;
  background-color: white;
}

  </style>
  