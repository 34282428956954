<template>
  <div @click="openChat" class="flex w-full justify-between items-center cursor-pointer p-5 k-br-cyan rounded-xl my-5">
    <span class="flex justify-start items-center text-left">
      <img :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
      <span class="message user-message">{{ chatDetails.messages[0].text }}</span>
    </span>
    <span>{{ getDate(chatDetails.modified_at) }}</span>
  </div>
</template>

<script>
export default {
  name: "chat",
  props: {
    chatDetails: Object
  },
  methods: {
    openChat() {
      let messages = []
      this.chatDetails.messages.forEach(element => {
        console.log(element)
        console.log(element.type)
        messages.push({
          'text': element.text,
          'isUser': element.type == 'user'
        })
      });
      this.$emit('chat-messages', messages)
    },
    getDate(dateString) {
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric'
      }) + ", "+dateObject.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
      });
      return formattedDate;
    }

  },
}
</script>