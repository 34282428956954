<template>
  <div class="flex text-left justify-start">
    <img :src="require('@/assets/img/bot.svg')" alt="user-icon" class="h-10" />
    <div class="message text-left pb-3">
      <p class="font-semibold mb-5">
        Strategic recommendations for top lanes:
      </p>

      <div class="flex">
        <div class="w-1/4">

          <div class="mb-5 k-br-cyan rounded-lg relative">
            <select @change="strategy=''" v-model="activeLane" class="form-select w-full cursor-pointer px-3 pr-10 py-2 border font-semibold rounded-md">
              <option v-for="lane in lanes" :key="lane.id">{{ lane.name }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <!-- Dropdown Icon (Downward-pointing Triangle) -->
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path fill="#55A8B5" d="M1 6h18L10 18 1 6z" />
              </svg>
            </div>
          </div>

          <div class="k-br-cyan rounded-lg p-3 space-y-4 text-sm">
            <div class="font-bold">Parameters determined -</div>
            <div>
              <label for="marketPriceTrend" class="block mb-1">Market Price Trend</label>
              <div class="mb-5 rounded-lg relative">
                <select v-model="marketPriceTrend" class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer">
                  <option value="Rising">Rising</option>
                  <option value="Stable">Stable</option>
                  <option value="Falling">Falling</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="marketCapacityTrend" class="block mb-1">Market Capacity Trend</label>
              <div class="mb-5 rounded-lg relative">
                <select v-model="marketCapacityTrend"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer">
                  <option value="Rising">Rising</option>
                  <option value="Stable">Stable</option>
                  <option value="Falling">Falling</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="priceBenchmarking" class="block mb-1">Price Benchmarking</label>
              <div class="mb-5 rounded-lg relative">
                <select v-model="priceBenchmarking"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer">
                  <option value="Higher than market">Higher than market</option>
                  <option value="Lower than market">Lower than market</option>
                  <option value="As per market">As per market</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label for="supplierBenchmarking" class="block mb-1">Supplier Benchmaking</label>
              <div class="mb-5 rounded-lg relative">
                <select v-model="supplierBenchmarking"
                  class="form-select w-full px-3 pr-10 py-2 border rounded-md cursor-pointer">
                  <option value="Potential suppliers available">Potential suppliers available</option>
                  <option value="No new Suppliers">No new suppliers</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <!-- Dropdown Icon (Downward-pointing Triangle) -->
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M1 6h18L10 18 1 6z" />
                  </svg>
                </div>
              </div>
            </div>

            <button v-ripple @click="refresh" class="w-full border k-bg-pink text-white rounded-md py-2">Refresh Strategy</button>
          </div>
        </div>
        <div class="w-3/4 k-br-cyan rounded-lg mx-5 p-5">
          <p class="font-semibold mb-2">Strategy Output</p>
          <img v-if="!showStrategyOutput" src="@/assets/gif/loading-gif.gif" alt="Loading..." class="loading-gif h-20" />
          <p v-if="showStrategyOutput" v-html="getStrategy">           
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lanes, refreshStrategy } from "../utils/constants.js";
export default {
  name: "ChatbotFilters",
  data() {
    return {
      locations: ["top", "bottom", "start", "end", "center"],
      location: "end",
      activeLaneId: null,
      marketPriceTrend: 'Stable',
      marketCapacityTrend: 'Stable',
      priceBenchmarking: 'As per market',
      supplierBenchmarking: 'Potential suppliers available',
      showStrategyOutput: true,
      activeLane: 'Melbourne to Hong Kong',
      lanes: lanes,
      strategy: "",
      refreshStrategy: refreshStrategy
    };
  },
  watch: {
    getStrategy() {
      this.showStrategyOutput = false;
      setTimeout(() => {
        this.showStrategyOutput = true;
      }, 3000);
    },
  },
  computed: {
    getStrategy() {
      if(this.strategy != ""){
        return this.strategy
      }
      const x = this.lanes.find((lane) => lane.name === this.activeLane);
      this.marketPriceTrend = x.marketPriceTrend
      this.marketCapacityTrend = x.marketCapacityTrend
      this.priceBenchmarking = x.priceBenchmarking
      this.supplierBenchmarking = x.supplierBenchmarking
      return x ? x.strategy : '';
    }
  },
  methods: {
    refresh() {
      this.strategy = this.refreshStrategy.find((x) => this.marketPriceTrend == x.marketPriceTrend &&
        this.marketCapacityTrend == x.marketCapacityTrend &&
        this.priceBenchmarking == x.priceBenchmarking &&
        this.supplierBenchmarking == x.supplierBenchmarking).strategy || ""
    }
  }
};
</script>

<style scoped>
.message {
  padding: 8px 20px;
  word-wrap: break-word;
  width: 100%;
  border-radius: 8px;
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}
.select-bg {
  background-color: #EEEEEE;
  color: #8E8E8E
}
</style>
