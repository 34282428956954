<template>
  <div class="flex items-center h-screen">
    <!-- Left half with an image -->
    <Loader :loading="loading" />
    <div class="w-1/2 h-screen relative flex justify-center items-center">
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-3xl">Sign in</h2>
        <p class="text-md text-gray-500 my-5">
          Welcome back! Please Sign in to your account.
        </p>
        <button v-ripple @click="signIn" class="k-bg-pink text-white py-3 rounded-lg mt-5"
          style="padding-left: 80px; padding-right: 80px">
          Sign In with Kearney SSO
        </button>
        <div class="w-2/3 text-justify text-xs mt-10">
          By clicking on 'Sign In', you confirm to have read the 'Kearney
          Privacy Statement' and provide consent to use your personal
          information like Name and Email ID.
          <a class="privacy-statement" href="https://www.kearney.com/client-privacy-policy">View Privacy Statement</a>
        </div>
      </div>
    </div>

    <div class="w-1/2 h-screen relative flex justify-center items-center">
      <img :src="require('@/assets/img/sso.png')" alt="Image" class="w-full h-screen object-cover" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { pca } from "@/config/authConfig.js";
import Loader from '@/components/Loader.vue'
import { useStore } from "vuex";
export default {
  name: "LoginView",
  components: {
    Loader
  },
  setup(_, context) {
    const loading = ref(false);
    const router = useRouter();
    const store = useStore();

    const signIn = async () => {
      const loginRequest = {
        scopes: ["User.Read", "openid", "profile", "email"],
      };

      try {
        await pca.initialize();
        const response = await pca.loginPopup(loginRequest);
        if (response.account) {
          console.log(response)
          const name = response.account.name.split(', ')
          loading.value = true;
          fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user-information`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
              'email': response.account.username,
              'firstName': name[1],
              'lastName': name[0]
            }
            ),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                const userData = {
                  userId: data.userId,
                  email: data.email,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  projects: data.projects
                };
                store.commit('setUser', userData);
                router.push({ name: "home" });
              } else {
                alert("Authentication failed!");
                router.push({ name: "login" });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              loading.value = false;
            })
        }
      } catch (error) {
        alert("Authentication failed!");
        console.error("Authentication error:", error);
      }
    };
    return { signIn, loading };
  }
};
</script>

<style scoped>
.privacy-statement {
  color: #a0186a;
  text-decoration: underline;
}
</style>
