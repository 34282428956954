<template>
    <div>
        <p class="mt-3 mb-1 font-bold">{{ }} <span class="k-text-purple capitalize"><span class="text-black">Capacity</span> - {{ trendMap[data["Markets Capacity Trend(Quarterly)"]] }}</span></p>
        <p class=" font-bold">{{ }} <span class="k-text-purple capitalize"><span class="text-black">Price</span> - {{ trendMap[data["Markets Price Trend (Quarterly)"]] }}</span></p>
        <div class="py-3">
            <p class="mb-2 text-xs" style="color: #6D6D6D;" v-html="data.Strategy"></p>
            <p v-for="url in data.urls" :key="url" class="k-text-pink text-xs mb-1">
                URL: <a :href="url" target="_blank">{{ url }}</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                "Markets Capacity Trend(Quarterly)": "",
                "Markets Price Trend (Quarterly)": "",
                "Strategy": " "
            })
        }
    },
    data(){
        return {
            trendMap: {
                "Rising":"Increasing",
                "Falling": "Decreasing",
                "Stable": "Stable"
            }
        }
    }
}
</script>