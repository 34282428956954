<template>
  <div>
    <div v-if="isOpen" class="flex flex-col justify-between panel text-left rounded-l-lg">
      <div>
        <div class="flex justify-between items-center mb-5">
          <span class="font-bold">Drafts</span>
          <button @click="$emit('close-panel')" class="close-button">×</button>
        </div>

        <div class="overflow-y-auto draft-area" style="max-height: 90vh;">
          <div class="mb-5">
            <p class="flex justify-between">
              <span class="font-bold">Draft 1 (Yantian-WC)</span>
              <span>03/10/24</span>
            </p>
            <p class="text-xs">Our procurement strategy will be encased on a quarterly sourcing negotiation cycle.</p>
          </div>
          <div class="mb-5">
            <p class="flex justify-between">
              <span class="font-bold">Draft 1 (Yantian-WC)</span>
              <span>03/10/24</span>
            </p>
            <p class="text-xs">Our procurement strategy will be encased on a quarterly sourcing negotiation cycle.</p>
          </div>
          <div class="mb-5">
            <p class="flex justify-between">
              <span class="font-bold">Draft 1 (Yantian-WC)</span>
              <span>03/10/24</span>
            </p>
            <p class="text-xs">Our procurement strategy will be encased on a quarterly sourcing negotiation cycle.</p>
          </div>
          <div class="mb-5">
            <p class="flex justify-between">
              <span class="font-bold">Draft 1 (Yantian-WC)</span>
              <span>03/10/24</span>
            </p>
            <p class="text-xs">Our procurement strategy will be encased on a quarterly sourcing negotiation cycle.</p>
          </div>
          <div class="mb-5">
            <p class="flex justify-between">
              <span class="font-bold">Draft 1 (Yantian-WC)</span>
              <span>03/10/24</span>
            </p>
            <p class="text-xs">Our procurement strategy will be encased on a quarterly sourcing negotiation cycle.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen:Boolean
  }
};
</script>

<style scoped>
.panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  /* Adjust the width as needed */
  height: 100%;
  background-color: #fff;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px;
  padding: 20px;
  transition: transform 0.3s ease;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.draft-area::-webkit-scrollbar {
  width: 4px;
}

.draft-area::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}

.k-pink-button {
  color: white;
  background-color: #A0186A;
}
</style>