<template>
  <div class="flex navbar justify-between items-center mt-5">
    <div class="flex items-center">
      <router-link :to="'/home'" @click="$store.dispatch('setTimestamp', -1)"><img :src="logo" /></router-link>
      <div v-if="labelVisible" class="ml-5 text-white">
        <span :class="{ 'dark-mode': darkMode }">GenAI Category Strategy</span>
      </div>
    </div>
    <div v-if="otherInfoVisible" class="flex items-center">
      <!-- <div class="flex items-center">
        <span class="text-white">Help</span>
        <img :src="require('@/assets/img/help.svg')" alt="" class="inline ml-1 h-5">
      </div> -->
      <div :class="{ 'border': darkMode, 'dark-mode': darkMode, 'light-mode': !darkMode }" class="button-section rounded-lg ml-5">
        <div @click="handleClick('history')" class="flex items-center px-8 rounded-lg"
          :class="{ 'k-bg-pink': activeButton === 'history', 'text-white': activeButton === 'history' }">
          <img class="inline mr-1" :src="historyLogo" />
          <button class="py-1" disabled>History</button>
        </div>

        <div @click="handleClick('main')" class="flex items-center px-8 rounded-lg"
          :class="{ 'k-bg-pink': activeButton === 'main', 'text-white': activeButton === 'main' }">
          <img class="inline mr-1" :src="require('@/assets/img/chat.svg')" />
          <button class="py-1" disabled>Main</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    otherInfoVisible: Boolean,
    labelVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeButton: "",
      darkMode: false,
      logo: require('@/assets/img/logo-white.svg'),
      historyLogo: require('@/assets/img/collection.svg')
    };
  },
  mounted() {
    const mode = this.$route.name;
    if (mode == 'history') {
      this.activeButton = 'history'
    } else {
      this.activeButton = 'main'
    }
    if (mode == "signals" || mode=="signalsaluminum" || mode == "strategy" || mode == "strategyaluminum") {
      this.darkMode = true
      this.logo = require('@/assets/img/logo-black.svg')
      this.historyLogo = require('@/assets/img/chat-black.svg')
    }
  },
  methods: {
    handleClick(value) {
      this.activeButton = value;
      if (value == 'history') {
        this.$router.push({ name: 'history', params: { mode: this.$route.params.mode } })
      } else {
        this.$router.push({ name: 'chatbot', params: { mode: this.$route.params.mode } })
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  height: 10vh;
  width: 90vw;
}

.dark-mode {
  color: black;
}

.light-mode {
  color: white;
}

.button-section {
  border: 1px solid white;
  padding: 3px;
  display: flex;
}
</style>
