<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">
    <Loader :loading="loading" />
    <Navbar :otherInfoVisible="true" />
    <div class="chatbot-container bg-white">
      <div class="flex justify-between items-center pb-3 border-b">
        <span class="font-bold text-3xl">Chat History</span>
        <div class="flex justify-between">
          <div class="mr-5">
            <label for="" class="mr-1">Start</label>
            <input type="date" v-model="startDate">
          </div>
          <div class="mr-5">
            <label for="" class="mr-1">End</label>
            <input type="date" v-model="endDate">
          </div>
          <button @click="applyFilter" v-ripple class="time-period-btn">Apply
          </button>
        </div>
        <!-- <button class="time-period-btn">
          <span>Select Time Period</span>
          <img :src="require('@/assets/img/caret-down.svg')" alt="" class="inline">
        </button> -->
      </div>
      <div v-if="!showdetails" class="chat-messages pr-5">
        <div v-if="filteredChats.length > 0">
          <div v-for="chat in filteredChats" :key="chat.chat_id">
            <Chat :chatDetails="chat" @chat-messages="handleMessages" />
          </div>
        </div>
        <div v-else>
          <p class="my-10">No Chat History Available</p>
        </div>
      </div>

      <div v-if="showdetails" class="text-left mb-5"><button class="back-btn border-none flex items-center"
          @click="showdetails = false"><img :src="require('@/assets/img/caret-left.svg')" alt=""
            class="inline"><span class="underline">Back</span></button></div>
      <div v-if="showdetails" class="chat-messages" ref="chatMessages">
        <span class="w-full flex" v-for="(message, index) in messages" :key="index" :class="{
          'justify-end': message.isUser,
          'justify-start': !message.isUser,
        }">
          <div class="flex items-start" :class="{
            'justify-end': message.isUser,
            'justify-start': !message.isUser,
          }">
            <img v-if="!message.isUser" :src="require('@/assets/img/bot.svg')" alt="bot-icon" class="h-10" />
            <div class="message" :class="{
              'user-message': message.isUser,
              'bot-message': !message.isUser,
            }">
              {{ message.text }}
            </div>
            <img v-if="message.isUser" :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
          </div>
        </span>
      </div>


    </div>






  </div>
</template>
  
<script>
import io from 'socket.io-client';
import axios from "axios";
import ChatbotOptionsList from "@/components/ChatbotOptionsList.vue";
import Loader from '@/components/Loader.vue'
import ChatbotFilters from "@/components/ChatbotFilters.vue";
import ChatbotTables from "@/components/ChatbotTables.vue";
import Navbar from "@/components/NavbarComponent.vue";
import Chat from "@/components/Chat.vue";

export default {
  name: "HistoryView",
  components: {
    ChatbotOptionsList,
    ChatbotFilters,
    ChatbotTables,
    Navbar,
    Loader,
    Chat
  },
  data() {
    return {
      userMessage: "",
      chats: [],
      filteredChats: [],
      messages: [],
      showdetails: false,
      loading: false,
      isLoading: false,
      initialComponent: null,
      startDate: "",
      endDate: ""
    };
  },
  mounted() {
    const mode = this.$route.params.mode;
    this.loading = true;


    fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get-chats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        'user_id': this.$store.getters.getUser.userId,
        'chat_category': mode
      }
      ),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        this.chats = data
        this.filteredChats = data
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })

    if (mode === "market-insights") {
      this.initialComponent = "ChatbotOptionsList";
    } else if (mode === "strategy-recommendation") {
      this.initialComponent = "ChatbotFilters";
    } else if (mode === "supplier-insights") {
      this.initialComponent = "ChatbotOptionsList";
    } else if (mode === "spend-analysis") {
      this.initialComponent = "ChatbotTables";
    } else if (mode === "price-benchmarking") {
      this.initialComponent = "ChatbotOptionsList";
    }
  },
  methods: {
    handleMessages(messages) {
      this.messages = messages
      this.showdetails = true
    },
    processQuery(question) {
      this.userMessage = question;
      this.sendMessage();
    },
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    applyFilter() {
      this.filteredChats = this.chats.filter((element) => {
  const elementTime = new Date(element.modified_at).getTime();
  const startTime = new Date(this.startDate).setHours(0, 0, 0, 0);
  const endTime = new Date(this.endDate).setHours(23, 59, 59, 999);
  
  return elementTime >= startTime && elementTime <= endTime;
});
      console.log(this.filteredChats)
    },
    addToHistory() {
      const apiUrl = "";
      axios
        .post(apiUrl, { messages: this.messages })
        .then((response) => {
          console.log("POST success:", response.data);
          this.result = response.data;
          // show a success message
        })
        .catch((error) => {
          console.error("POST error:", error);
        });
    },
    async sendMessage() {
      const userText = this.userMessage.trim();
      if (userText === "") return;

      this.messages.push({
        text: userText,
        isUser: true,
        timeStamp: new Date(),
      });
      this.scrollToBottom();
      this.userMessage = "";
      this.isLoading = true;


      const socket = io(`${process.env.VUE_APP_API_BASE_URL}`);

      const payload = { query: userText };
      socket.emit('stream_data_event', payload);

      let responseText = "No response from the backend.";
      socket.on('stream_data', (data) => {
        console.log(`Received stream data: ${data.data}`);
        console.log(data)

        if (typeof data.data === "object") {
          responseText = JSON.stringify(data.data);
        } else {
          responseText = data.data;
        }

        const botResponse = {
          text: responseText,
          isUser: false,
          timeStamp: new Date(),
        };

        this.messages.push(botResponse);
        this.scrollToBottom();
      });


      socket.on('stream_end_event', (data) => {
        socket.disconnect();
        this.isLoading = false;
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatMessages.scrollTop =
          this.$refs.chatMessages.scrollHeight;
      });
    },

    generateRows(message) {
      const parsedResponse = JSON.parse(message);
      const keys = Object.keys(parsedResponse.response);
      const rowCount = Math.max(
        ...keys.map((key) => Object.keys(parsedResponse.response[key]).length)
      );
      const rows = [];

      for (let i = 0; i < rowCount; i++) {
        const rowData = {};
        keys.forEach((key) => {
          const value = parsedResponse.response[key][i] || "";
          rowData[key] = value;
        });
        rows.push(rowData);
      }

      return rows;
    },
  },
};
</script>
  
<style scoped>
.chatbot-container {
  height: 85vh;
  width: 80vw;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.time-period-btn {
  color: white;
  background-color: #A0186A;
}

.back-btn {
  color: #A0186A;
  background-color: white;
  padding-left: 0px;
  margin-left: 0px;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #a0186a;
  color: #fff;
}

.chatbot {
  background-image: url("../assets/img/wave-bg.png");
  background-size: cover;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.chat-messages::-webkit-scrollbar {
  width: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
  