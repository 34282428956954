<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">
    <Navbar :otherInfoVisible="false" />
    <Loader :loading="loading" />
    <div class="chatbot-container bg-white">
      <div class="w-1/5 pr-5 text-left flex flex-col justify-between">
        <div>
          <label class="font-bold text-lg">Select Lane</label>
          <div class="mb-5 mt-2 k-br-cyan rounded-lg relative">
            <select v-model="selectedLaneID" class="form-select w-full cursor-pointer px-3 pr-10 py-2 border font-semibold rounded-md">
              <option v-for="lane in lanes" :key="lane.id" :value="lane.id">{{ lane.name }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <!-- Dropdown Icon (Downward-pointing Triangle) -->
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path fill="#55A8B5" d="M1 6h18L10 18 1 6z" />
              </svg>
            </div>
          </div>
        </div>
        <Timeline style="max-height: 80vh;" @date-selected="getSignals"/>
      </div>
      <div class="chat-messages w-4/5">
        <NewsSignals :selectedLaneID="selectedLaneID" :newsData="newsData"/>
        <ReportSignals :selectedLaneID="selectedLaneID" :marketData="marketData"/>
        <TabularSignals :selectedLaneID="selectedLaneID" :mainData="mainData" />
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';
import axios from "axios";
import Loader from '@/components/Loader.vue'
import ChatbotOptionsList from "@/components/ChatbotOptionsList.vue";
import ChatbotFilters from "@/components/ChatbotFilters.vue";
import ChatbotTables from "@/components/ChatbotTables.vue";
import Navbar from "@/components/NavbarComponent.vue";
import Popup from "@/components/Popup.vue";
import NewsSignals from '@/components/NewsSignals.vue';
import ReportSignals from '@/components/ReportSignals.vue';
import Timeline from '@/components/Timeline.vue';
import TabularSignals from '@/components/TabularSignals.vue'
import { laneNames } from "../utils/constants.js";

export default {
  name: "SignalsView",
  components: {
    ChatbotOptionsList,
    ChatbotFilters,
    ChatbotTables,
    NewsSignals,
    ReportSignals,
    TabularSignals,
    Navbar,
    Popup,
    Loader,
    Timeline
  },
  data() {
    return {
      userMessage: "",
      messages: [],
      loading: false,
      isLoading: false,
      initialComponent: null,
      showPopup: false,
      lanes: laneNames,
      selectedLaneID: 1,
      popupQuestion: "Are you sure you want to start a new chat?",
      marketData: {},
      newsData: {},
      mainData: {}
    };
  },
  methods: {
    getSignals() {
      this.loading = true;
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/signals`;
      axios
        .post(apiUrl, { timestamp: this.$store.getters.getTimestamp })
        .then((response) => {
          this.$store.dispatch('setStrategy', response.data);
          console.log(this.$store.getters.getAllDates)
          this.marketData = response.data.Result.market
          this.newsData = response.data.Result.news
          this.mainData = response.data.Result.main
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
  mounted(){
    this.getSignals()
  }
};
</script>

<style scoped>
.chatbot-container {
  height: 85vh;
  width: 90vw;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ccc;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #a0186a;
  color: #fff;
}

.chatbot {
  background-size: cover;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.chat-messages::-webkit-scrollbar {
  width: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>