<template>
  <div
    class="home text-white items-center h-screen flex flex-col justify-between"
  >
    <Navbar :labelVisible="false" />
    <div>
      <div class="text-3xl mb-5">
        <p>
          Hello <span class="font-bold">{{ userFromStore }}</span
          >! I am Infinity,
        </p>
        <p>your AI consultant, I can help you</p>
        <p>with the following activities.</p>
      </div>
      <div>
        <div v-if="spendDataAvailable" class="options mb-15">
          <button
            v-ripple
            @click="
              $router.push({
                name: 'actions',
                params: { mode: 'market-insights' },
              })
            "
            class="option-button inline px-5 rounded-xl"
          >
            Market Insights
          </button>
          <button
            v-ripple
            @click="
              $router.push({
                name: 'actions',
                params: { mode: 'strategy-recommendation' },
              })
            "
            class="option-button inline px-5 rounded-xl"
          >
            Strategy Recommendation
          </button>
          <button
            v-ripple
            @click="
              $router.push({
                name: 'actions',
                params: { mode: 'price-benchmarking' },
              })
            "
            class="option-button inline px-5 rounded-xl"
          >
            Price Benchmarking
          </button>
          <button
            v-ripple
            @click="
              $router.push({
                name: 'actions',
                params: { mode: 'supplier-insights' },
              })
            "
            class="option-button inline px-5 rounded-xl"
          >
            Supplier Insights
          </button>
        </div>

        <div v-if="!spendDataAvailable" class="options mb-15">
          <button
            v-ripple
            @click="
              $router.push({
                name: 'actions',
                params: { mode: 'market-insights' },
              })
            "
            class="option-button inline px-5 rounded-xl"
          >
            Market Insights
          </button>
          <div class="flex justify-between overlay-content items-center">
            <div class="lock items-center">
              <img
                class="inline mr-1"
                :src="require('@/assets/img/lock.svg')"
              />
              <span class="mr-5"
                >Upload your spend data to get customized insights</span
              >
              <!-- <div v-ripple class="flex items-center px-8 rounded-lg k-bg-pink">
                <img
                  class="inline mr-1"
                  :src="require('@/assets/img/chat.svg')"
                />
                <button class="py-1">Upload data</button>
              </div> -->
            </div>
            <button
              v-ripple
              @click="
                $router.push({
                  name: 'actions',
                  params: { mode: 'strategy-recommendation' },
                })
              "
              class="disabled-option-button inline px-5 rounded-xl"
            >
              Strategy Recommendation
            </button>
            <button
              v-ripple
              @click="
                $router.push({
                  name: 'actions',
                  params: { mode: 'supplier-insights' },
                })
              "
              class="disabled-option-button inline px-5 rounded-xl"
            >
              Supplier Insights
            </button>
            <button
              v-ripple
              @click="
                $router.push({
                  name: 'actions',
                  params: { mode: 'spend-analysis' },
                })
              "
              class="disabled-option-button inline px-5 rounded-xl"
            >
              Spend Analysis
            </button>
            <button
              v-ripple
              @click="
                $router.push({
                  name: 'actions',
                  params: { mode: 'price-benchmarking' },
                })
              "
              class="disabled-option-button inline px-5 rounded-xl"
            >
              Price Benchmarking
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/NavbarComponent.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    Navbar,
  },
  data() {
    return {
      userName: "Kearney User",
      spendDataAvailable: true,
    };
  },
  methods: {
    getDates() {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/get-all-dates`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.$store.dispatch('setAllDates', response.data.Result);
        })
        .catch((error) => {
          console.error("POST error:", error);
        })
    },
  },
  mounted(){
    this.getDates()
  },
  computed: {
    userFromStore() {
      const firstName = this.$store.getters.getUser.firstName;
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    },
  },
};
</script>

<style scoped>
.home {
  background-image: url("../assets/img/bot-bg.png");
  background-size: cover;
}

.overlay-content {
  position: relative;
  background-color: #1d1d1d99;
  padding: 20px;
  border-radius: 8px;
  z-index: 999;
  width: 80%;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  width: 80vw;
}

.option-button {
  height: 7rem;
  font-weight: 600;
  width: 18%;
  background-color: transparent;
  border: 3px solid white;
}

.disabled-option-button {
  height: 7rem;
  font-weight: 600;
  width: 23%;
  color: rgb(54, 54, 54);
  background-color: #28282899;
}

.lock {
  display: flex;
  position: absolute;
  font-weight: 700;
}

.option-button:active {
  background-color: #a0186a;
}

.option-button:disabled {
  background-color: rgb(107, 114, 128);
}
</style>
