<template>
  <div>
    <ChatbotOptionsList v-if="!pdfReceived && !query" @send-query="processQuery" />
    <span class="w-full flex justify-start">
      <img v-if="query" :src="require('@/assets/img/bot.svg')" alt="user-icon" class="h-10" />
      <div v-if="query" class="message text-left  pb-3">
        <p class="k-br-cyan p-5 rounded-lg flex justify-between items-center">
          <input type="text" class="w-3/4" v-model="query" :disabled="!editQuestion">
          <button v-if="!editQuestion" @click="editQuestion = true"><img :src="require('@/assets/img/regenerate.svg')"
              alt="regenerate-icon" class="inline h-5" /></button>
          <button v-ripple v-else class="px-5 ml-5 py-1 k-bg-pink text-white rounded-md" @click="regenerate">Submit</button>
        </p>
        <div v-if="pdfReceived && !responseData" class="mt-5">
          <p class="font-bold mb-2">Select the most relevant file</p>
          <div class=" k-br-cyan rounded-lg">
            <table class="min-w-full">
              <thead>
                <tr class="">
                  <th
                    class="w-1/6 px-6 py-3 border-b-2 border-gray-300 text-left leading-4 font-bold text-gray-700 tracking-wider">
                    Select File
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 font-bold text-gray-700 tracking-wider">
                    File Name</th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 font-bold text-gray-700 tracking-wider">
                    Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-300">
                    <input type="radio" v-model="selectedItem" :value="item.name">
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-300">{{ item.name }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-t border-gray-300">{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
            <button v-if="selectedItem !==null" v-ripple class="px-5 py-2 m-5 k-bg-pink text-white rounded-md" @click="getSolution">Submit Selected File</button>
          </div>
        </div>
      </div>
    </span>
    <AluminumTabularOutput :responseData="responseData" v-if="responseData" />
    <div class="w-full flex justify-around">
          <img v-if="isLoading" src="@/assets/gif/loading-gif.gif" alt="Loading..." class="loading-gif h-20" />
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { questions, descriptions } from "../utils/constants.js";
import ChatbotOptionsList from "@/components/ChatbotOptionsList.vue";
import AluminumTabularOutput from "@/components/AluminumTabularOutput.vue"
export default {
  name: "ChatbotFileSelect",
  components: {
    ChatbotOptionsList,
    AluminumTabularOutput
  },
  data() {
    return {
      selectedItem: null,
      isLoading: false,
      answer: false,
      responseData: null,
      query: "",
      pdfReceived: false,
      editQuestion: false,
      title: questions[this.$route.params.mode + "-" + this.$route.params.category].title,
      questions: questions[this.$route.params.mode + "-" + this.$route.params.category].questions,
      items: []
    };
  },
  methods: {
    handleLiClick(index, question) {
      this.$emit("send-query", question);
    },

    processQuery(question) {
      this.query = question;
      this.sendQuery()
    },

    regenerate(){
      this.editQuestion = false
      this.pdfReceived = false;
      this.responseData = null;
      this.items = []
      this.sendQuery()
    },

    sendQuery(){
      this.isLoading=true;
      const apiUrl = `${process.env.VUE_APP_ALUMINUM_API_BASE_URL}/api-aluminum/answerDocsL`;
      axios
        .post(apiUrl, {'query': this.query, 'questionCategory': "Aluminum"})
        .then((response) => {
          console.log(response)
          response.data.l.docs_l.forEach(element => {
            this.items.push({name: element, description: descriptions[element] })
          });
          this.pdfReceived=true
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.isLoading = false
        });
    },
    getSolution(){
      this.isLoading = true
      const apiUrl = `${process.env.VUE_APP_ALUMINUM_API_BASE_URL}/api-aluminum/answerDocsCategory`;
      axios
        .post(apiUrl, {"query":this.query,"answer_length":"short","addtables":true,"questionCategory":"Aluminum","selectedElement":[this.selectedItem]})
        .then((response) => {
          this.responseData = response.data
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.isLoading = false
        });
    }
  },
};
</script>

<style scoped>
.message {
  padding: 8px 10px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  width: 80%;
  border-radius: 8px;
  display: block;
}

li {
  cursor: pointer;
}

li:hover {
  background-color: #55A8B5;
  color: white
}
</style>
