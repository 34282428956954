<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">
    <Navbar :otherInfoVisible="true" />
    <Loader :loading="loading" />
    <div class="chatbot-container bg-white">
      <div class="chat-messages" ref="chatMessages">
        <!-- <span class="w-full flex justify-end">
          <div class="message user-message">Ocean Freight</div>
          <img :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
        </span> -->
        <component :is="initialComponent" @send-query="processQuery" />
        <span class="w-full flex" v-for="(message, index) in messages" :key="index" :class="{
      'justify-end': message.isUser,
      'justify-start': !message.isUser,
    }">
          <div class="flex items-start" :class="{
      'justify-end': message.isUser,
      'justify-start': !message.isUser,
    }">
            <img v-if="!message.isUser" :src="require('@/assets/img/bot.svg')" alt="bot-icon" class="h-10" />
            <div class="message" :class="{
      'user-message': message.isUser,
      'bot-message': !message.isUser,
    }" v-html="message.text">

            </div>
            <img v-if="message.isUser" :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
          </div>
        </span>
        <div class="w-full flex justify-around">
          <img v-if="isLoading" src="@/assets/gif/loading-gif.gif" alt="Loading..." class="loading-gif" />
        </div>
      </div>
      <div class="user-input flex">
        <div class="relative flex-1">
          <input class="w-full h-10 pr-12" v-model="userMessage" @keyup.enter="sendMessage"
            placeholder="Type here..." />
          <div class="absolute inset-y-0 right-0 flex items-center pr-2">
            <!-- Clickable Image (Checkmark) -->
            <svg class="cursor-pointer fill-current h-4 w-6" width="20" height="20" fill="none"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="sendMessage">
              <path
                d="M21.2428 12.4371C21.4016 12.3489 21.5 12.1816 21.5 12C21.5 11.8184 21.4016 11.6511 21.2428 11.5629L18.9605 10.295C14.464 7.79689 9.72391 5.76488 4.81421 4.2306L4.14914 4.02276C3.99732 3.97532 3.83198 4.00294 3.70383 4.09716C3.57568 4.19138 3.5 4.34094 3.5 4.5V10.25C3.5 10.5159 3.70816 10.7353 3.97372 10.7493L4.98336 10.8025C7.44497 10.932 9.89156 11.2659 12.2979 11.8006L12.5362 11.8536C12.5892 11.8654 12.6122 11.887 12.625 11.9042C12.6411 11.926 12.6536 11.9594 12.6536 12C12.6536 12.0406 12.6411 12.0741 12.625 12.0958C12.6122 12.113 12.5892 12.1347 12.5362 12.1464L12.2979 12.1994C9.89157 12.7341 7.44496 13.068 4.98334 13.1976L3.97372 13.2507C3.70816 13.2647 3.5 13.4841 3.5 13.75V19.5C3.5 19.6591 3.57568 19.8086 3.70383 19.9029C3.83198 19.9971 3.99732 20.0247 4.14914 19.9772L4.81422 19.7694C9.72391 18.2351 14.464 16.2031 18.9605 13.705L21.2428 12.4371Z"
                fill="black" />
            </svg>
          </div>
        </div>
        <button :disabled="messages.length == 0 || isLoading" @click="showPopup = true" v-ripple
          class="p-5 bg-black text-white w-1/7">
          <span class="mr-3">+</span>New Chat
        </button>
      </div>
    </div>
    <Popup :is-visible="showPopup" :question="popupQuestion" @accept="handleAccept" @close="handleClose" />
  </div>
</template>

<script>
import io from 'socket.io-client';
import axios from "axios";
import Loader from '@/components/Loader.vue'
import ChatbotOptionsList from "@/components/ChatbotOptionsList.vue";
import ChatbotFilters from "@/components/ChatbotFilters.vue";
import ChatbotTables from "@/components/ChatbotTables.vue";
import Navbar from "@/components/NavbarComponent.vue";
import Popup from "@/components/Popup.vue";
import NewsSignals from '@/components/NewsSignals.vue';
import ChatbotFileSelect from '@/components/ChatbotFileSelect.vue'

export default {
  name: "ChatbotView",
  components: {
    ChatbotOptionsList,
    ChatbotFilters,
    ChatbotTables,
    ChatbotFileSelect,
    NewsSignals,
    Navbar,
    Popup,
    Loader
  },
  data() {
    return {
      userMessage: "",
      messages: [],
      data: [],
      loading: false,
      isLoading: false,
      initialComponent: null,
      eventSource: null,
      showPopup: false,
      popupQuestion: "Are you sure you want to start a new chat?"
    };
  },
  mounted() {
    const mode = this.$route.params.mode;
    const category = this.$route.params.category;
    if (category === "aluminum") {
      this.initialComponent = "ChatbotFileSelect";
    } else {
      if (mode === "market-insights") {
        this.initialComponent = "ChatbotOptionsList";
      } else if (mode === "strategy-recommendation") {
        this.initialComponent = "NewsSignals";
      } else if (mode === "supplier-insights") {
        this.initialComponent = "ChatbotOptionsList";
      } else if (mode === "spend-analysis") {
        this.initialComponent = "ChatbotTables";
      } else if (mode === "price-benchmarking") {
        this.initialComponent = "ChatbotOptionsList";
      }
    }
  },
  methods: {
    processQuery(question) {
      this.userMessage = question;
      this.sendMessage();
    },
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    addToHistory() {
      this.loading = true;
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/add-chat`;
      axios
        .post(apiUrl, { chat_category: this.$route.params.mode, user_id: this.$store.getters.getUser.userId, messages: this.messages })
        .then((response) => {
          this.messages = []
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.loading = false;
        });
    },
    handleAccept() {
      this.showPopup = false;
      this.addToHistory();
    },
    handleClose() {
      this.showPopup = false;
    },

    async sendMessage() {
      const userText = this.userMessage.trim();
      if (userText === "") return;

      this.messages.push({
        text: userText,
        isUser: true,
        timeStamp: new Date(),
      });
      this.scrollToBottom();
      this.userMessage = "";
      this.isLoading = true;

      // Close existing event source if any
      if (this.eventSource) {
        this.eventSource.close();
        this.data = [];  // Clear previous data
      }

      // Initialize a new EventSource
      let timestamp = -1;
      const type = this.$route.params.mode == 'strategy-recommendation' ? 'strategy' : 'non-strategy'
      if (this.$store.getters.getTimestamp == -1) {
        const dates = this.$store.getters.getAllDates
        timestamp = Object.values(dates)[0]
      } else {
        timestamp = this.$store.getters.getTimestamp
      }
      const query = timestamp + "@@@" + "cache" + "@@@" + userText
      this.eventSource = new EventSource(`${process.env.VUE_APP_API_BASE_URL}/api/stream/${query}`);

      this.eventSource.onmessage = (event) => {
        let responseText = "No response from the backend.";
        if (typeof event.data === "object") {
          responseText = JSON.stringify(event.data);
        } else {
          responseText = event.data;
        }
        if (responseText.trim() == 'end') {
          this.isLoading = false
        } else {
          const botResponse = {
            text: responseText.replaceAll('\\n', '<br>'),
            isUser: false,
            timeStamp: new Date(),
          };
          this.messages.push(botResponse);
          this.scrollToBottom();
        }

      };

      this.eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        this.eventSource.close();
      };
    },


    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatMessages.scrollTop =
          this.$refs.chatMessages.scrollHeight;
      });
    },

    generateRows(message) {
      const parsedResponse = JSON.parse(message);
      const keys = Object.keys(parsedResponse.response);
      const rowCount = Math.max(
        ...keys.map((key) => Object.keys(parsedResponse.response[key]).length)
      );
      const rows = [];

      for (let i = 0; i < rowCount; i++) {
        const rowData = {};
        keys.forEach((key) => {
          const value = parsedResponse.response[key][i] || "";
          rowData[key] = value;
        });
        rows.push(rowData);
      }

      return rows;
    },
  },
};
</script>

<style scoped>
.chatbot-container {
  height: 85vh;
  width: 90vw;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #a0186a;
  color: #fff;
}

.chatbot {
  background-image: url("../assets/img/wave-bg.png");
  background-size: cover;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.chat-messages::-webkit-scrollbar {
  width: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
