<template>
  <v-timeline side="end" class="rounded-md k-br-cyan overflow-y-auto timeline" style="max-height: 40vh;">
    <v-timeline-item v-for="(item, i) in items" :key="i" class="bg-gray" :dot-color="item == getTimestamp ? '#55A8B5' : 'transparent'"
      size="extra-small">
      <button @click="handleTimelineClick(item)" class="text-xs mr-5">{{ formattedDate(i) }}</button>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    items: {},
    selected: -1,
    apiUrl: ""
  }),
  computed: {
    getTimestamp(){
      return this.$store.getters.getTimestamp
    }
  },
  methods: {
    getDates() {
      const name = this.$route.name;
      this.setAPIURL(name);
      this.loading = true;
      axios
        .get(this.apiUrl)
        .then((response) => {
          this.items = response.data.Result
          this.$store.dispatch('setAllDates', response.data.Result);
          this.handleTimelineClick(Object.values(response.data.Result)[0])
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.loading = false
        });
    },

    handleTimelineClick(item){
      this.$store.dispatch('setTimestamp', item);
      this.$emit("date-selected")
      
    },

    formattedDate(timestamp){
      let date = new Date(timestamp)
      return date.toLocaleDateString();
    },

    setAPIURL(name) {
      if (name == "signalsaluminum" || name == 'strategyaluminum') {
        this.apiUrl = `${process.env.VUE_APP_ALUMINUM_API_BASE_URL}/api-aluminum/get-all-dates`;
      } else {
        this.apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/get-all-dates`;
      }
    }
  },
  mounted(){
    const name = this.$route.name;
    this.setAPIURL(name);
    if(this.getTimestamp == -1){
      this.getDates()
    } else {
      this.items = this.$store.getters.getAllDates
    }
  }

}
</script>

<style scoped>
.timeline::-webkit-scrollbar {
  width: 4px;
}

.timeline::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}
</style>