<template>
  <span class="w-full flex justify-start">
    <img :src="require('@/assets/img/bot.svg')" alt="user-icon" class="h-10" />
    <div class="message text-left k-br-cyan pb-3">
      <p class="font-semibold mb-5">{{ title }}</p>
      <ul class="mb-2">
        <li
          v-ripple
          v-for="(question, index) in questions"
          :key="index"
          @click="handleLiClick(index, question)"
          :class="[
            'k-br-cyan',
            'p-2',
            'rounded-lg',
            'mt-2',
            {
              'k-bg-cyan': selectedLi === index,
              'text-white': selectedLi === index,
            },
          ]"
        >
          {{ question }}
        </li>
      </ul>
      <div class="flex justify-end">
        <button class="font-semibold underline k-text-pink">
          Ask a question?
        </button>
      </div>
    </div>
  </span>
</template>

<script>
import { questions } from "../utils/constants.js";
export default {
  name: "ChatbotOptionsList",
  data() {
    return {
      selectedLi: null,
      title: questions[this.$route.params.mode + "-" + this.$route.params.category].title,
      questions: questions[this.$route.params.mode +"-"+ this.$route.params.category].questions,
    };
  },
  methods: {
    handleLiClick(index, question) {
      this.$emit("send-query", question);
    },
  },
};
</script>

<style scoped>
.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

li {
  cursor: pointer;
}

li:hover {
  background-color: #55A8B5 ;
  color: white
}
</style>
