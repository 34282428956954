<template>
  <div class="container mx-auto mt-5 text-xs border-2 rounded-xl shadow-xl">
    <p class="py-2 px-4 font-semibold">Top Suppliers</p>
    <table class="min-w-full">
      <thead>
        <tr>
          <th class="py-1 px-4 border-b">Carrier</th>
          <th class="py-1 px-4 border-b">Alliance</th>
          <th class="py-1 px-4 border-b">volume</th>
          <th class="py-1 px-4 border-b">spend</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.carrier">
          <td class="py-1 px-4 border-b">{{ item.carrier }}</td>
          <td class="py-1 px-4 border-b">{{ item.alliance }}</td>
          <td class="py-1 px-4 border-b">{{ item.volume }}</td>
          <td class="py-1 px-4 border-b">{{ item.spend }}</td>
        </tr>
        <tr class="k-bg-cyan">
          <td class="py-2 px-4 rounded-bl-xl">Total</td>
          <td class="py-2 px-4">-</td>
          <td class="py-2 px-4">74230.90</td>
          <td class="py-2 px-4 rounded-br-xl">1164744</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
/* Add your other styles here */
</style>

<script>
export default {
  data() {
    return {
      items: [
      {
          carrier: "CMA",
          alliance: "Ocean",
          type: "VO",
          volume: "39005.71",
          spend: "924642",
        },
        {
          carrier: "COSCO",
          alliance: "Ocean",
          type: "VO",
          volume: "28205.15",
          spend: "113020",
        },
        {
          carrier: "EVERGREEN",
          alliance: "Ocean",
          type: "VO",
          volume: "7020.04",
          spend: "127082",
        },
      ],
    };
  },
};
</script>
