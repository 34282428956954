<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">
    <Navbar :otherInfoVisible="true" />
    <div class="chatbot-container bg-white">
      <div class="chat-messages" ref="chatMessages">
        <span class="w-full flex justify-end">
          <div class="message user-message">Ocean Freight</div>
          <img :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
        </span>
        <span class="w-full flex" v-for="(message, index) in messages" :key="index" :class="{
          'justify-end': message.isUser,
          'justify-start': !message.isUser,
        }">
          <div class="flex items-start" :class="{
            'justify-end': message.isUser,
            'justify-start': !message.isUser,
          }">
            <img v-if="!message.isUser" :src="require('@/assets/img/bot.svg')" alt="bot-icon" class="h-10" />
            <div class="message" :class="{
              'user-message': message.isUser,
              'bot-message': !message.isUser,
            }">
              {{ message.text }}
            </div>
            <img v-if="message.isUser" :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/NavbarComponent.vue";

export default {
  name: "ChatHistoryView",
  components: {
    Navbar,
  },
  props: {
    messagesList: Array
  },
  data(){
    return {
      messages: []
    }
  },
  watch: {
    messagesList(newList) {
      console.log('jdlkf')
      console.log(newList)
      // Update the localMode when the prop changes
      this.messages = newList;
    }
  },

  mounted() {
    const mode = this.$route.params.mode;
  },
};
</script>

<style scoped>
.chatbot-container {
  height: 85vh;
  width: 80vw;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #a0186a;
  color: #fff;
}

.chatbot {
  background-image: url("../assets/img/wave-bg.png");
  background-size: cover;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.chat-messages::-webkit-scrollbar {
  width: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
