<template>
  <span class="w-full flex justify-start">
    <img :src="require('@/assets/img/bot.svg')" alt="user-icon" class="h-10" />
    <div class="message text-left pb-3">
      <p class="font-semibold mb-5">Spend Summary:</p>
      <div class="flex justify-between">
        <div class="py-3 pl-5 w-1/3 k-br-cyan rounded-lg mr-5">
          <p class="text-xs">Total Spend</p>
          <p class="text-md pt-1 font-bold">$ 24762</p>
        </div>
        <div class="py-3 pl-5 w-1/3 k-br-cyan rounded-lg mr-5">
          <p class="text-xs">Num of lanes</p>
          <p class="text-md pt-1 font-bold">402</p>
        </div>
        <div class="py-3 pl-5 w-1/3 k-br-cyan rounded-lg">
          <p class="text-xs">Num of suppliers</p>
          <p class="text-md pt-1 font-bold">5</p>
        </div>
      </div>
      <div class="flex justify-between">
        <Table class="w-1/2 mr-2" />
        <TopSupplierTable class="w-1/2" />
      </div>
      <ul class="flex justify-between mt-5">
        <!-- <li v-ripple class="p-2 w-1/4 k-br-cyan rounded-lg mr-5">
          <p class="text-xs">Can you summarize my spend?</p>
        </li>

        <li v-ripple class="p-2 w-1/4 k-br-cyan rounded-lg mr-5">
          <p class="text-xs">Can you summarize my spend?</p>
        </li>

        <li v-ripple class="p-2 w-1/4 k-br-cyan rounded-lg mr-5">
          <p class="text-xs">Can you summarize my spend?</p>
        </li>

        <li v-ripple class="p-2 w-1/4 k-br-cyan rounded-lg">
          <p class="text-xs">Can you summarize my spend?</p>
        </li> -->

        <li
          v-ripple
          v-for="(question, index) in questions"
          :key="index"
          @click="handleLiClick(index, question)"
          :class="[
            'k-br-cyan',
            'p-2',
            'rounded-lg',
            'mr-2',
            'w-1/4',
            'text-xs',
            {
              'k-bg-cyan': selectedLi === index,
              'text-white': selectedLi === index,
            },
          ]"
        >
          {{ question }}
        </li>

      </ul>
    </div>
  </span>
</template>

<script>
import Table from "@/components/TableComponent.vue";
import TopSupplierTable from "@/components/TopSupplierTable.vue";
export default {
  name: "ChatbotTables",
  components: {
    Table,
    TopSupplierTable
  },
  data() {
    return {
      selectedLi: null,
      questions: [
        "Can you summarize my spend?",
        "Which are my top spend lanes?",
        "Which were my top spend lanes in october/Q4 last year?",
        "Which are my top suppliers?",
      ],
    };
  },
  methods: {
    handleLiClick(index, question) {
      this.selectedLi = index;
      this.$emit("send-query", question);
    },
  },
};
</script>

<style scoped>
.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}
</style>
