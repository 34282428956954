import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
      userId: 0,
      firstName: '',
      lastName: '',
      email: '',
      projects: [],
      categories: [],
    },
    laneData: {
      laneId: 1,
      source: ''
    },
    timestamp: -1,
    strategy: {},
    allDates: {}
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
      localStorage.setItem('user', JSON.stringify(userData));
    },
    clearUser(state) {
      state.user = {
        userId: 0,
        firstName: '',
        lastName: '',
        email: '',
        projects: [],
        categories: [],
      };
      localStorage.removeItem('user');
    },
    setLaneData(state, laneData) {
      state.laneData = laneData
    },
    setStrategyData(state, strategy){
      state.strategy = strategy
    },
    setAllDatesData(state, dates){
      state.allDates = dates
    },
    setTimestampData(state, timestamp){
      state.timestamp = timestamp
    }
  },
  actions: {
    login({ commit }, userData) {
      commit('setUser', userData);
    },
    logout({ commit }) {
      commit('clearUser');
    },
    setLane({ commit }, laneData) {
      commit('setLaneData', laneData)
    },
    setStrategy({ commit }, strategyData) {
      commit('setStrategyData', strategyData)
    },
    setAllDates({ commit }, allDatesData) {
      commit('setAllDatesData', allDatesData)
    },
    setTimestamp({ commit }, timestampData) {
      commit('setTimestampData', timestampData)
    },
  },
  getters: {
    getUser: (state) => state.user,
    getLane: (state) => state.laneData,
    getStrategy: (state) => state.strategy,
    getAllDates: (state) => state.allDates,
    getTimestamp: (state) => state.timestamp
  },
});
